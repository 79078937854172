import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useWorksList() {

    //User toast
    const toast = useToast()

    const refWorkListTable = ref(null)

    const tableColumns = [
        {key: 'id',stickyColumn: true, sortable: true},
        {key: 'user_id', label:'NAME', sortable:true},
        {key: 'title', label:'Title', sortable:true},
        {key: 'remark', label:'REMARK', sortable: true},
        {key: 'work_date', label:'DATE', sortable: true},
        {key: 'time_from', label:'FROM'},
        {key: 'time_to', label: 'TO'},
        {key: 'approve_bit', label:'Checked'},
        {key: 'admin_remark'},
        {key: 'action'}
    ]

    //Filter
    const userFilter = ref(null)
    const dateFromFilter = ref(null)
    const dateToFilter = ref(null)

    const perPage = ref(10)
    const totalWorks = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refWorkListTable.value ? refWorkListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalWorks.value,
        }
    })

    const refetchData = () => {
        refWorkListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter, dateFromFilter, dateToFilter], () => {
        refetchData()
    })

    const fetchWorks = (ctx, callback) => {
        store.dispatch('admin-work/fetchDetailWorks',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            user_id: userFilter.value,
            date_from: dateFromFilter.value,
            date_to: dateToFilter.value,
        })
        .then(response =>{
            const {works, total} = response.data
            callback(works)
            totalWorks.value = total
        
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching work list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchWorks,
        tableColumns,
        perPage,
        currentPage,
        totalWorks,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refWorkListTable,
        ability,
        refetchData,
        userFilter,
        dateFromFilter,
        dateToFilter,
    }

}