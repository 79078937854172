<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>User</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            label="name"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Date From</label>
            <b-form-datepicker
            id="date_from"
            placeholder="Date From"
            local="en"
            :value="dateFromFilter"
            @input="(val) => $emit('update:dateFromFilter', val)"
            :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            }"
        />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Date To</label>
            <b-form-datepicker
            id="date_to"
            placeholder="Date To"
            local="en"
            :value="dateToFilter"
            @input="(val) => $emit('update:dateToFilter', val)"
            :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            }"
        />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label></label>
        <div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="reset"
                style="height:40px;"
              >
                Reset
              </b-button>
              </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";

export default {
    directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods:{
      reset(){ 
        this.$emit('update:userFilter', null)
        this.$emit('update:dateFromFilter', null)
        this.$emit('update:dateToFilter', null)
      }
  },
  props: {
    userFilter: {
      type: [Number, null],
      default: null,
    },
    dateFromFilter:{
        type:[String, null],
        default:null,
    },
    dateToFilter:{
        type:[String, null],
        default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
