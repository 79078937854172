import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWorks(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/work/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchDetailWorks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/work/detail_list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
  },
    fetchWork(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/work`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addWork(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/work', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateWork(ctx,data) {
        return new Promise((resolve, reject) => {
            axiosIns.put('/work', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteWork(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          axiosIns.delete('/work', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/user/list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
  },
    
  },
}
